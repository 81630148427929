import React from "react";
import { graphql } from "gatsby";

import Article from "../components/commons/Article";
import SubPage from "../components/organisms/SubPage";

export default function EventPage({
  data: { sanityEvent, moreCaseStudies, sanityPage },
}) {
  const data = sanityEvent;

  return (
    <SubPage
      title={data.seoTitle || data.title}
      description={data.seoDescription}
      pathname={data.slug.current}
      banner={data.heroImage}
      page={sanityPage}
    >
      <Article
        title={data.title}
        body={data._rawBody}
        date={{
          value: data.publishedAtRaw,
          label: data.publishedAt,
        }}
        sx={{ mb: "gridGap" }}
      />
    </SubPage>
  );
}

export const pageQuery = graphql`
  query EventPage($sanityId: String!) {
    sanityEvent(_id: { eq: $sanityId }) {
      title
      seoTitle
      seoDescription
      slug {
        current
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    sanityPage(_id: { eq: "events" }) {
      slug {
        current
      }
      blocks {
        ...Blocks
      }
    }
  }
`;
